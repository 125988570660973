<template>
  <select class="custom-select" :class="{'border-danger': hasError}" :value="value" @input="handleChange">
    <option value=""></option>
    <option v-for="option in options" :key="option.value" :value="option.value">
      {{ option.label }}
    </option>
    <option value="other">Autre...</option>
  </select>
</template>

<script>
import { orderBy } from 'lodash-es'
import inputMixin from '@/modules/forms/components/inputs/inputMixin'
import http from '@/utils/http'

export default {
  mixins: [inputMixin],
  data () {
    return {
      options: []
    }
  },
  methods: {
    async fetchData () {
      const { data: carriers } = await http.get('/v1/carriers')

      this.options = orderBy(carriers.map((carrier) => ({
        label: carrier.name,
        value: carrier.name
      })), ['label'], ['asc'])
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
